/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://secure.wikimedia.org/wikipedia/es/wiki/Archivo:European-parliament-strasbourg-inside.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 240px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHQo7iuRg//xAAZEAEAAwEBAAAAAAAAAAAAAAAAARIUAhH/2gAIAQEAAQUC1RDW08LPVn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAABkQAgMTL/2gAIAQEABj8CwqclTCqf/8QAHRAAAgIBBQAAAAAAAAAAAAAAAAExgWERIUFx0f/aAAgBAQABPyHhNoaIYMlT03YISd1aP//aAAwDAQACAAMAAAAQCC//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAeEAEBAAIBBQEAAAAAAAAAAAABEQAhoTFBUXGBwf/aAAgBAQABPxDUgPW05cdySt0QDw15xo/Y5ALeli/mS7ImwO2MMr7Ln//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"European-parliament-strasbourg-inside.jpg\"\n        title=\"European-parliament-strasbourg-inside.jpg\"\n        src=\"/static/9925e67807c4a4415c562e423994645c/09b79/240px-European-parliament-strasbourg-inside.jpg\"\n        srcset=\"/static/9925e67807c4a4415c562e423994645c/ff44c/240px-European-parliament-strasbourg-inside.jpg 158w,\n/static/9925e67807c4a4415c562e423994645c/09b79/240px-European-parliament-strasbourg-inside.jpg 240w\"\n        sizes=\"(max-width: 240px) 100vw, 240px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Hoy se ha aprobado desgraciadamente la extensión de 50 a 70 años en la protección en las grabaciones musicales. Todas esas grabaciones que a día de hoy tendrían que pasar a dominio público tendrán que esperar 20 años más, o no, si no hacemos algo, lo mas probable es que dentro de unos años vuelvan a extender el Copyright robándonos del dominio público, lo que nos pertenece a todos."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
